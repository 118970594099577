import axios, { AxiosRequestConfig } from 'axios';
import * as API from './apis';


export default class ImageService {
    getHeader(hasCache: boolean = true) {
        const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            ...cacheControl
        };
    }

    // handleErrorHttp(err:any) {

    //     const errorResponse = err.response ? err.response.data : err.response;
    //     if (JSON.stringify(err) !== '{}') {
    //         if (errorResponse) {
    //             Swal.fire({
    //                 ...DefaultConstants.SWAL_COMMON_STYLE,
    //                 title: errorResponse.title
    //                     ? formatMessage({ id: errorResponse.title })
    //                     : '',
    //                 text: errorResponse.message
    //                     ? formatMessage({ id: errorResponse.message })
    //                     : '',
    //                 icon: 'error',
    //                 confirmButtonText: formatMessage({ id: 'BUTTON.CLOSE' })
    //             });
    //         } else if (!errorResponse && err) {
    //             Swal.fire({
    //                 ...DefaultConstants.SWAL_COMMON_STYLE,
    //                 text: formatMessage({ id: 'MESSAGE_NETWORK_ERROR' }),
    //                 icon: 'warning',
    //                 confirmButtonText: 'OK'
    //             });
    //         }
    //     }
    //     throw errorResponse;

    // }

    async uploadImage(filename: string, base64Data: string) {
        const defaultHeaders = this.getHeader();

        let opts: AxiosRequestConfig = {
            url: API.API_UPLOAD_PLAN,
            method: 'post',
            headers: defaultHeaders,
            data: {
                "fileName": filename,
                "image": base64Data
            }
        };

        return axios(opts)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            })
    }

    async exportPlanToImage(html: string): Promise<string> {
        const defaultHeaders = this.getHeader();

        let opts: AxiosRequestConfig = {
            url: API.API_EXPORT_PLAN_TO_IMAGE,
            method: 'post',
            headers: defaultHeaders,
            data: {
                "html": html
            }
        };

        return axios(opts)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            })
    }
}