interface Option {
    id: string,
    value: string
}

export default interface Weight {
    weightName: string;
    weight: number;
    selectedValue: string;
    options: Option[];
    otherNotes: string[];
}

export class WeightOption implements Weight {
    weightName = '';
    weight = 0;
    selectedValue = "0";
    options: Option[] = [];
    otherNotes: string[] = [];

    constructor(_weightName: string, _weight: number, _options: Option[]) {
        this.weightName = _weightName;
        this.weight = _weight;
        this.options = _options;
    }
}

export class WeightText implements Weight {
    weightName = '';
    weight = 0;
    selectedValue: string = '';
    options: Option[] = [];
    otherNotes: string[] = [];

    constructor(_weightName: string, _weight: number) {
        this.weightName = _weightName;
        this.weight = _weight;
    }
}