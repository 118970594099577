import React from "react";
import { renderToString } from 'react-dom/server';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';
import { toJpeg } from 'html-to-image';
import Swal from 'sweetalert2';
import ThisInterface from "./interface";
import template from "./template";
import ReviewPlan from '../../components/ReviewPlan';
import { EmptyPlan } from "../../models/Plan";
import { WeightOption, WeightText } from "../../models/Weight";


class Home extends React.Component<ThisInterface["props"], ThisInterface["state"]> {
    constructor(props: ThisInterface["props"]) {
        super(props);
        this.state = {
            isDownloading: false,
            today: moment().format('dddd, MMMM Do YYYY'),
            planData: new EmptyPlan()
        }
    }

    onChange = (event: any) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [name]: value
                }
            }
        })
    }

    onCheckboxChange = (weight: WeightOption, isChecked: boolean) => {
        let weightCloned = { ...weight };
        if (!isChecked) {
            if (weight.weightName === "howMentality" || weight.weightName === "howTrap" || weight.weightName === "howTime") {
                weightCloned.selectedValue = "";
            }
            else {
                weightCloned.selectedValue = "0";
            }
        }

        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [weight.weightName]: weightCloned
                }
            }
        }, () => this.calculateWinrate());
    };

    onValueChange = (weight: WeightText, text: string) => {
        let weightCloned = { ...weight };
        weightCloned.selectedValue = text;

        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [weight.weightName]: weightCloned
                }
            }
        }, () => this.calculateWinrate());
    }

    addNote = (weight: WeightOption) => {
        const notes = [...weight.otherNotes, ""];
        const weightCloned = {
            ...weight,
            otherNotes: notes
        };
        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [weight.weightName]: weightCloned
                }
            }
        });
    }

    onNoteChange = (weight: WeightOption, text: string, index: number) => {
        const notes = [...weight.otherNotes];
        notes[index] = text;

        const weightCloned = {
            ...weight,
            otherNotes: notes
        };

        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [weight.weightName]: weightCloned
                }
            }
        });
    };

    removeNote = (weight: WeightOption, index: number) => {
        const notes = [...weight.otherNotes];
        notes.splice(index, 1);

        const weightCloned = {
            ...weight,
            otherNotes: notes
        };

        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    [weight.weightName]: weightCloned
                }
            }
        });
    };

    calculateWinrate = () => {
        let { howTrend, howSignal, howDemand, howTime, howMentality, howTrap } = this.state.planData;

        var winRate = 50
            + (howTrend.selectedValue !== "0" ? howTrend.weight : 0)
            + (howSignal.selectedValue !== "0" ? howSignal.weight : 0)
            + (howDemand.selectedValue !== "0" ? howDemand.weight : 0)
            + (howTime.selectedValue !== "" ? howTime.weight : 0)
            + (howMentality.selectedValue !== "" ? howMentality.weight : 0)
            + (howTrap.selectedValue !== "" ? howTrap.weight : 0);

        this.setState(prevState => {
            return {
                ...prevState,
                planData: {
                    ...prevState.planData,
                    whyWinrate: winRate.toString() + '%'
                }
            }
        });
    }

    onExportPlan = (event: any) => {
        event.preventDefault();
        //only use with uploadPlanImage
        // this.setState({
        //     isDownloading: true
        // });
        Swal.fire({
            text: "Vui lòng chờ...",
            backdrop: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })
        this.exportPlanToImage();
    }

    componentDidUpdate = (_prevProps: ThisInterface["props"], prevState: ThisInterface["state"]) => {
        if (this.state.isDownloading) {
            //this.uploadPlanImage();            
        }
    }

    //generate screenshot at frontend
    uploadPlanImage = () => {
        const _this = this;
        const options = {
            backgroundColor: '#fff',
        };

        toJpeg(document.querySelector("#root") ?? new HTMLDivElement(), options)
            .then(function (dataUrl) {
                const fileName = uuidv1().replace(/-/g, '') + '.jpg';

                _this.props.imageService.uploadImage(fileName, dataUrl)
                    .then(res => {
                        _this.setState({
                            isDownloading: false
                        })
                        _this.showPopup(fileName, res);
                    })
                    .catch((error) => {
                        _this.setState({
                            isDownloading: false
                        })
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error
                        })
                    })
            });
    }

    //generate screenshot at backend
    exportPlanToImage = () => {
        var _this = this;
        var html = renderToString(<ReviewPlan {...this.state} />);
        //console.log(html);
        this.props.imageService.exportPlanToImage(html)
            .then(res => {
                var fileName = res.substring(res.lastIndexOf('/') + 1);
                _this.showPopup(fileName, res);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error
                })
            })
    }

    showPopup = (fileName: string, imageUrl: string) => {
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            icon: 'success',
            input: 'text',
            inputValue: imageUrl,
            inputAttributes: {
                "readOnly": "true",
                "id": "plan_url"
            },
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonColor: '#017f36',
            confirmButtonText: 'Tải về máy',
            denyButtonColor: '#017f36',
            denyButtonText: "Copy",
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return fetch(imageUrl)
                    .then(response => {
                        return response.blob();
                    })
                    .then(blob => {
                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.download = "quanlyvon.com_kehoach_" + moment().format('DD-MM-YYYY_SSSS') + ".jpg";
                        a.click();
                        return false;
                    });
            },
            preDeny: () => {
                var input = Swal.getInput();
                input?.select();
                document.execCommand("copy");
                Swal.getDenyButton()!.textContent = "Copied";
                return false;
            },
        })
    }


    render() {
        return template(this);
    }
}

export default Home;