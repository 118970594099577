import React, { Component } from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import ApiContext from '../utils/ApiContext';
import ImageService from '../services/image.service';
import Home from './Home';
import ReviewPlan from './ReviewPlan';


interface Props {
    imageService: ImageService;
}

class Layout extends Component<Props> {

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    {/* <Header /> */}
                    <main className="main-content">
                        <ApiContext.Consumer>
                            {svrs =>
                                <Switch>
                                    <Route path="/review">
                                        <ReviewPlan />
                                    </Route>
                                    <Route path="/">
                                        <Home {...svrs} />
                                    </Route>
                                </Switch>
                            }
                        </ApiContext.Consumer>
                    </main>
                    {/* <Footer /> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Layout;