import { WeightText, WeightOption } from './Weight';
import {
    trends
} from '../data/trends';
import {
    signals
} from '../data/signals';
import {
    supplyDemands
} from '../data/supplyDemands';
import {
    times
} from '../data/times';
import {
    conditions
} from '../data/conditions';


export default interface Plan {
    name: string;
    whatMarket: string;
    whatOrder: string;
    whatTimeFrame: string;

    when: WeightOption;
    whenCondition: WeightOption;

    whoBuy: string;
    whoSell: string;
    whoOutside: string;
    whoShark: string;
    whoExpectation: string;

    whyRiskRewardRatio: string;
    whyWinrate: string;

    howTrend: WeightOption;
    howSignal: WeightOption;
    howDemand: WeightOption;
    howTime: WeightText;
    howMentality: WeightText;
    howTrap: WeightText;

    howmuchVolume: string;
    howmuchEntry: string;
    howmuchStoplot: string;
    howmuchTakeProfit1: string;
    howmuchTakeProfit2: string;
}

export class EmptyPlan implements Plan {
    name = '';
    whatMarket = 'EUR/USD';
    whatOrder = 'Lệnh MUA';
    whatTimeFrame = '1 Phút';

    when = new WeightOption("when", 0, times);
    whenCondition = new WeightOption("whenCondition", 0, conditions);

    whoBuy = '';
    whoSell = '';
    whoOutside = '';
    whoShark = '';
    whoExpectation = '';

    whyRiskRewardRatio = '';
    whyWinrate = '50%';

    howTrend = new WeightOption("howTrend", 5, trends);
    howSignal = new WeightOption("howSignal", 15, signals);
    howDemand = new WeightOption("howDemand", 5, supplyDemands);
    howTime = new WeightText("howTime", 5);
    howMentality = new WeightText("howMentality", 10);
    howTrap = new WeightText("howTrap", 10);

    howmuchVolume = '';
    howmuchEntry = '';
    howmuchStoplot = '';
    howmuchTakeProfit1 = '';
    howmuchTakeProfit2 = '';
}