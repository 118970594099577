import {
  BrowserRouter as Router,
} from "react-router-dom";

import ApiContext, { services } from './utils/ApiContext';
import Layout from './pages/Layout';

function App() {
  return (
    <Router>
      <ApiContext.Provider value={services}>
        <Layout  {...services} />
      </ApiContext.Provider>
    </Router>
  );
}

export default App;
