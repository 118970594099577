import Weight from '../../models/Weight';

interface Props {
    item: Weight;
    allowOtherNotes: boolean;
    onCheckboxChange?: (weight: Weight, isChecked: boolean) => void;
    onTextboxChange?: (weight: Weight, value: string) => void;
    onSelectChange?: (weight: Weight, value: string) => void;
    addNote: (weight: Weight) => void;
    onNoteChange: (weight: Weight, text: string, index: number) => void;
    removeNote: (weight: Weight, index: number) => void;
}

export default function HowItem(props: Props) {
    let {
        item,
        allowOtherNotes,
        onCheckboxChange,
        onTextboxChange,
        onSelectChange,
        addNote,
        onNoteChange,
        removeNote
    } = props;
    return (
        <div className="tp-howitem">
            <div className="input-group">
                {onCheckboxChange &&
                    <input className="form-check-input" type="checkbox"
                        checked={item.selectedValue !== "0" && item.selectedValue !== ""}
                        onChange={(event) => onCheckboxChange && onCheckboxChange(item, event.target.checked)} />
                }
                {onSelectChange &&
                    <select className="form-select" name="selectedValue" value={item.selectedValue}
                        onChange={(event) => onSelectChange && onSelectChange(item, event.target.value)}>
                        {
                            item.options.map((m) => <option key={m.id} value={m.id}>{m.value}</option>)
                        }
                    </select>
                }

                {onTextboxChange &&
                    <input type="text" className="form-control tp-input" name="value"
                        value={item.selectedValue}
                        onChange={(event) => onTextboxChange && onTextboxChange(item, event.target.value)} />
                }

                {allowOtherNotes && <i className="fas fa-plus" onClick={() => addNote(item)}></i>}
            </div>
            {
                item.otherNotes.map((text, index) => {
                    return (
                        <div key={index} className="input-group mb-3 tp-notes">
                            <input className='form-control tp-input' value={text} onChange={e => onNoteChange(item, e.target.value, index)} />
                            <i className="fas fa-minus-circle" onClick={() => removeNote(item, index)}></i>
                        </div>
                    )
                })
            }
        </div>
    )
}