import Plan from 'models/Plan';

interface Props {
    today: string;
    planData: Plan;
}

export default function ReviewPlan(props: Props) {
    const today = props.today;
    let {
        name,
        whatMarket,
        whatOrder,
        whatTimeFrame,
        when,
        whenCondition,
        whoBuy,
        whoSell,
        whoOutside,
        whoShark,
        whoExpectation,
        whyRiskRewardRatio,
        whyWinrate,
        howTrend,
        howSignal,
        howDemand,
        howTime,
        howMentality,
        howTrap,
        howmuchVolume,
        howmuchEntry,
        howmuchStoplot,
        howmuchTakeProfit1,
        howmuchTakeProfit2,
    } = props.planData;

    const howTrendText = howTrend.options.find(op => op.id === when.selectedValue)?.value
    const howSignalText = howSignal.options.find(op => op.id === when.selectedValue)?.value
    const howDemandText = howDemand.options.find(op => op.id === when.selectedValue)?.value
    const whenText = when.options.find(op => op.id === when.selectedValue)?.value
    const whenConditionText = whenCondition.options.find(op => op.id === when.selectedValue)?.value

    return (
        <>
            <h1 className="text-center">TRADING PLANNER</h1>
            <h5 className="text-center fw-light">NẾU BẠN KHÔNG LẬP KẾ HOẠCH KIẾM TIỀN.</h5>
            <h5 className="text-center fw-light mb-5">BẠN SẼ RƠI VÀO KẾ HOẠCH  KIẾM TIỀN CỦA KẺ KHÁC</h5>
            <div className="row">
                <div className="col-6">
                    <div className="row mb-3">
                        <label htmlFor="name" className="col-2 col-form-label">NAME
                        </label>
                        <div className="col-8">
                            <input type="text" className="form-control tp-input" id="name" name="name"
                                value={name}
                                disabled={true} />
                        </div>
                    </div>
                </div>
                <div className="col-6 text-end">
                    DATE: {today}
                </div>
            </div>

            {/* Body */}
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 1</span> - <span className="step-2">WHAT</span>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="row mb-3">
                                    <label htmlFor="whatMarket" className="col-md-3 col-sm-4 col-form-label">THỊ TRƯỜNG
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whatMarket"
                                            value={whatMarket}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whatOrder" className="col-md-3 col-sm-4 col-form-label">LỆNH
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whatOrder"
                                            value={whatOrder}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whatTimeFrame" className="col-md-3 col-sm-4 col-form-label">KHUNG GIỜ
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whatTimeFrame"
                                            value={whatTimeFrame}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 2</span> - <span className="step-2">HOW</span>
                            </span>
                        </div>
                        <div className="card-body how">
                            <div className="card-text">
                                <div className="watermark"></div>
                                <div className="row mb-4">
                                    <label htmlFor="howTrend" className="col-md-3 col-sm-4 col-form-label">XU HƯỚNG
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howTrend.selectedValue !== "0" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input" id="howTrend" value={howTrendText} disabled={true} />
                                        </div>
                                        {
                                            howTrend.otherNotes.map((note, index) => {
                                                return (
                                                    <div className="input-group">
                                                        <input key={index} type="text" className="form-control tp-input tp-note-viewonly mt-2" value={note} disabled={true} />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label htmlFor="howSignal" className="col-md-3 col-sm-4 col-form-label">TÍN HIỆU
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howSignal.selectedValue !== "0" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input" id="howSignal" value={howSignalText} disabled={true} />
                                        </div>
                                        {
                                            howSignal.otherNotes.map((note, index) => {
                                                return (
                                                    <div className="input-group">
                                                        <input key={index} type="text" className="form-control tp-input tp-note-viewonly mt-2" value={note} disabled={true} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label htmlFor="howDemand" className="col-md-3 col-sm-4 col-form-label">VÙNG CẢN
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howDemand.selectedValue !== "0" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input " id="howDemand" value={howDemandText} disabled={true} />
                                        </div>
                                        {
                                            howDemand.otherNotes.map((note, index) => {
                                                return (
                                                    <div className="input-group">
                                                        <input key={index} type="text" className="form-control tp-input tp-note-viewonly mt-2" value={note} disabled={true} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label htmlFor="howTime" className="col-md-3 col-sm-4 col-form-label">THỜI ĐIỂM
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howTime.selectedValue !== "" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input" id="howTime"
                                                value={howTime.selectedValue}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label htmlFor="howMentality" className="col-md-3 col-sm-4 col-form-label">TÂM LÝ
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howMentality.selectedValue !== "" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input" id="howMentality"
                                                value={howMentality.selectedValue}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label htmlFor="howTrap" className="col-md-3 col-sm-4 col-form-label">BẪY GIÁ
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <div className="input-group">
                                            <span className="input-group-text">{howTrap.selectedValue !== "" ? "YES" : "NO"}</span>
                                            <input type="text" className="form-control tp-input" id="howTrap"
                                                value={howTrap.selectedValue}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 3</span> - <span className="step-2">WHEN</span>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="row mb-3">
                                    <label htmlFor="when" className="col-md-3 col-sm-4 col-form-label">THỜI ĐIỂM
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="when" value={whenText} disabled={true} />
                                        {
                                            when.otherNotes.map((note) => {
                                                return (<input type="text" className="form-control tp-input" value={note} disabled={true} />)
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whenCondition" className="col-md-3 col-sm-4 col-form-label">ĐIỀU KIỆN</label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whenCondition" value={whenConditionText} disabled={true} />
                                        {
                                            whenCondition.otherNotes.map((note) => {
                                                return (<input type="text" className="form-control tp-input" value={note} disabled={true} />)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column">


                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 4</span> - <span className="step-2">HOW MUCH</span>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="row mb-3">
                                    <label htmlFor="howmuchVolume" className="col-md-3 col-sm-4 col-form-label">KHỐI LƯỢNG
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="howmuchVolume"
                                            value={howmuchVolume}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="howmuchEntry" className="col-md-3 col-sm-4 col-form-label">GIÁ MỞ LỆNH
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="howmuchEntry"
                                            value={howmuchEntry}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="howmuchStoplot" className="col-md-3 col-sm-4 col-form-label">DỪNG LỖ
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="howmuchStoplot"
                                            value={howmuchStoplot}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="howmuchTakeProfit1" className="col-md-3 col-sm-4 col-form-label">CHỐT LỜI 1
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="howmuchTakeProfit1"
                                            value={howmuchTakeProfit1}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="howmuchTakeProfit2" className="col-md-3 col-sm-4 col-form-label">CHỐT LỜI 2
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="howmuchTakeProfit2"
                                            value={howmuchTakeProfit2}
                                            disabled={true} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 5</span> - <span className="step-2">WHO</span>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="row mb-3">
                                    <label htmlFor="whoBuy" className="col-md-3 col-sm-4 col-form-label">NGƯỜI MUA</label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whoBuy"
                                            value={whoBuy}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whoSell" className="col-md-3 col-sm-4 col-form-label"> NGƯỜI BÁN
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whoSell"
                                            value={whoSell}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whoOutside" className="col-md-3 col-sm-4 col-form-label">NGƯỜI ĐỨNG
                                        NGOÀI
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whoOutside"
                                            value={whoOutside}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whoShark" className="col-md-3 col-sm-4 col-form-label">CÁ MẬP
                                    </label>
                                    <div className="col-md-9 col-sm-8">
                                        <input type="text" className="form-control tp-input" id="whoShark"
                                            value={whoShark}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className=" mb-3">
                                    <label htmlFor="whoExpectation" className="form-label">KẾ HOẠCH VÀ KỲ VỌNG CỦA
                                        ĐÁM ĐÔNG</label>
                                    <textarea className="form-control tp-txtarea" id="whoExpectation"
                                        value={whoExpectation}
                                        disabled={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="header-bullet"></div>
                            <div className="header-separator"></div>
                            <span className="header-text text-uppercase">
                                <span className="step-1">BƯỚC 6</span> - <span className="step-2">WHY</span>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div className="row mb-3">
                                    <label htmlFor="whyRiskRewardRatio" className="col-8 col-form-label">1. TỶ LỆ LỢI
                                        NHUẬN
                                    </label>
                                    <div className="col-4">
                                        <input type="text" className="form-control tp-input" id="whyRiskRewardRatio"
                                            value={whyRiskRewardRatio}
                                            disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="whyWinrate" className="col-8 col-form-label">2. XÁC XUẤT THẮNG
                                    </label>
                                    <div className="col-4">
                                        <input type="text" className="form-control tp-input" id="whyWinrate"
                                            value={whyWinrate}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="hidden" id="page_end" value="page_end"></input>
        </>
    )
}