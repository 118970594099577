export const timeframes = [
    { "id": 1, "value": "1 Phút" },
    { "id": 2, "value": "5 Phút" },
    { "id": 3, "value": "15 Phút" },
    { "id": 4, "value": "30 Phút" },
    { "id": 5, "value": "1 Giờ" },
    { "id": 6, "value": "4 Giờ" },
    { "id": 7, "value": "1 Ngày" },
    { "id": 8, "value": "1 Tuần" },
    { "id": 9, "value": "1 Tháng" }
]