export const markets = [
    { "id": 1, "value": "EUR/USD" },
    { "id": 2, "value": "GBP/USD" },
    { "id": 3, "value": "AUD/USD" },
    { "id": 4, "value": "NZD/USD" },
    { "id": 5, "value": "USD/CAD" },
    { "id": 6, "value": "USD/JPY" },
    { "id": 7, "value": "AUD/CAD" },
    { "id": 8, "value": "AUD/JPY" },
    { "id": 9, "value": "EUR/JPY" },
    { "id": 10, "value": "EUR/GBP" },
    { "id": 11, "value": "EUR/AUD" },
    { "id": 12, "value": "EUR/CAD" },
    { "id": 13, "value": "GBP/JPY" },
    { "id": 14, "value": "GBP/AUD" },
    { "id": 15, "value": "GBP/CAD" },
    { "id": 16, "value": "XAU/USD" },
    { "id": 17, "value": "XAG/USD" },
    { "id": 18, "value": "BTC/USD" },
    { "id": 19, "value": "ETH/USD" },
    { "id": 20, "value": "XRP/USD" },
    { "id": 21, "value": "US30" },
    { "id": 22, "value": "USOIL" },
    // { "id": 0, "value": "KHÁC (Tự điền)" }
]