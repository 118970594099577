import ThisInterface from './interface';
import ReviewPlan from '../../components/ReviewPlan';
import HowItem from '../../components/HowItem';
import {
    markets
} from '../../data/markets';
import {
    orders
} from '../../data/orders';
import {
    timeframes
} from '../../data/timeframes';


const templateBak = (_this: ThisInterface) => {
    let {
        isDownloading,
        today
    } = _this.state;
    let {
        name,
        whatMarket,
        whatOrder,
        whatTimeFrame,
        when,
        whenCondition,
        whoBuy,
        whoSell,
        whoOutside,
        whoShark,
        whoExpectation,
        whyRiskRewardRatio,
        whyWinrate,
        howTrend,
        howSignal,
        howDemand,
        howTime,
        howMentality,
        howTrap,
        howmuchVolume,
        howmuchEntry,
        howmuchStoplot,
        howmuchTakeProfit1,
        howmuchTakeProfit2,
    } = _this.state.planData;

    return (
        <>
            {isDownloading ? <ReviewPlan {..._this.state} />
                :
                <>
                    <h1 className="text-center">TRADING PLANNER</h1>
                    <h5 className="text-center fw-light">NẾU BẠN KHÔNG LẬP KẾ HOẠCH KIẾM TIỀN.</h5>
                    <h5 className="text-center fw-light mb-5">BẠN SẼ RƠI VÀO KẾ HOẠCH  KIẾM TIỀN CỦA KẺ KHÁC</h5>

                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="row mb-3">
                                <label htmlFor="name" className="col-2 col-form-label">NAME:
                                </label>
                                <div className="col-8">
                                    <input type="text" className="form-control tp-input" id="name" name="name"
                                        value={name}
                                        onChange={(event) => _this.onChange(event)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            DATE: {today}
                        </div>
                    </div>

                    {/* Body */}
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 1</span> - <span className="step-2">WHAT</span>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <div className="row mb-3">
                                            <label htmlFor="whatMarket" className="col-4 col-md-5 col-form-label">THỊ TRƯỜNG
                                            </label>
                                            <div className="col-8 col-md-7 ">
                                                <select id="whatMarket" name="whatMarket" className="form-select"
                                                    value={whatMarket}
                                                    onChange={(event) => _this.onChange(event)}>
                                                    {
                                                        markets.map((m) => <option key={m.id} value={m.value}>{m.value}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whatOrder" className="col-4 col-md-5 col-form-label">LỆNH
                                            </label>
                                            <div className="col-8 col-md-7">
                                                <select id="whatOrder" name="whatOrder" className="form-select"
                                                    value={whatOrder}
                                                    onChange={(event) => _this.onChange(event)}>
                                                    {
                                                        orders.map((m) => <option key={m.id} value={m.value}>{m.value}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whatTimeFrame" className="col-4 col-md-5 col-form-label">KHUNG GIỜ
                                            </label>
                                            <div className="col-8 col-md-7">
                                                <select id="whatTimeFrame" name="whatTimeFrame" className="form-select"
                                                    value={whatTimeFrame}
                                                    onChange={(event) => _this.onChange(event)}>
                                                    {
                                                        timeframes.map((m) => <option key={m.id} value={m.value}>{m.value}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3 d-flex">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 2</span> - <span className="step-2">HOW</span>
                                    </span>
                                </div>
                                <div className="card-body how">
                                    <div className="card-text">
                                        <div className="watermark"></div>
                                        <div className="row mb-4">
                                            <label htmlFor="howTrend" className="col-4 col-form-label">XU HƯỚNG
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howTrend}
                                                    allowOtherNotes={true}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onSelectChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label htmlFor="howSignal" className="col-4 col-form-label">TÍN HIỆU
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howSignal}
                                                    allowOtherNotes={true}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onSelectChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label htmlFor="howDemand" className="col-4 col-form-label">VÙNG CẢN
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howDemand}
                                                    allowOtherNotes={true}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onSelectChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label htmlFor="howTime" className="col-4 col-form-label">THỜI ĐIỂM
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howTime}
                                                    allowOtherNotes={false}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onTextboxChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label htmlFor="howMentality" className="col-4 col-form-label">TÂM LÝ
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howMentality}
                                                    allowOtherNotes={false}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onTextboxChange={(weight, text) => _this.onValueChange(weight, text)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label htmlFor="howTrap" className="col-4 col-form-label">BẪY GIÁ
                                            </label>
                                            <div className="col-8">
                                                <HowItem item={howTrap}
                                                    allowOtherNotes={false}
                                                    onCheckboxChange={(weight, event) => _this.onCheckboxChange(weight, event)}
                                                    onTextboxChange={(weight, text) => _this.onValueChange(weight, text)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 3</span> - <span className="step-2">WHEN</span>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <div className="row mb-3">
                                            <label htmlFor="when" className="col-4 col-md-5 col-form-label">THỜI ĐIỂM
                                            </label>
                                            <div className="col-8 col-md-7">
                                                <HowItem item={when}
                                                    allowOtherNotes={true}
                                                    onSelectChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whenCondition" className="col-4 col-md-5 col-form-label">ĐIỀU KIỆN</label>
                                            <div className="col-8 col-md-7">
                                                <HowItem item={whenCondition}
                                                    allowOtherNotes={true}
                                                    onSelectChange={(weight, value) => _this.onValueChange(weight, value)}
                                                    addNote={(weight) => _this.addNote(weight)}
                                                    onNoteChange={(weight, text, index) => _this.onNoteChange(weight, text, index)}
                                                    removeNote={(weight, index) => _this.removeNote(weight, index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 d-none d-sm-block">
                                <div className="col d-flex justify-content-center">
                                    <div className="d-grid gap-2 col-md-6 col-12">
                                        <button className="btn btn-primary" onClick={(event) => _this.onExportPlan(event)}>TẢI KẾ HOẠCH VỀ MÁY</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex flex-column">
                            <div className="card mb-3 d-flex">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 4</span> - <span className="step-2">HOW MUCH</span>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <div className="row mb-3">
                                            <label htmlFor="howmuchVolume" className="col-lg-4 col-md-5 col-form-label">KHỐI LƯỢNG
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="howmuchVolume" name="howmuchVolume"
                                                    value={howmuchVolume}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="howmuchEntry" className="col-lg-4 col-md-5 col-form-label">GIÁ MỞ LỆNH
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="howmuchEntry" name="howmuchEntry"
                                                    value={howmuchEntry}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="howmuchStoplot" className="col-lg-4 col-md-5 col-form-label">DỪNG LỖ
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="howmuchStoplot" name="howmuchStoplot"
                                                    value={howmuchStoplot}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="howmuchTakeProfit1" className="col-lg-4 col-md-5 col-form-label">CHỐT LỜI 1
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="howmuchTakeProfit1" name="howmuchTakeProfit1"
                                                    value={howmuchTakeProfit1}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="howmuchTakeProfit2" className="col-lg-4 col-md-5 col-form-label">CHỐT LỜI 2
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="howmuchTakeProfit2" name="howmuchTakeProfit2"
                                                    value={howmuchTakeProfit2}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 5</span> - <span className="step-2">WHO</span>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <div className="row mb-3">
                                            <label htmlFor="whoBuy" className="col-lg-4 col-md-5 col-form-label">NGƯỜI MUA</label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="whoBuy" name="whoBuy"
                                                    value={whoBuy}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whoSell" className="col-lg-4 col-md-5 col-form-label"> NGƯỜI BÁN
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="whoSell" name="whoSell"
                                                    value={whoSell}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whoOutside" className="col-lg-4 col-md-5 col-form-label">NGƯỜI ĐỨNG
                                                NGOÀI
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="whoOutside" name="whoOutside"
                                                    value={whoOutside}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whoShark" className="col-lg-4 col-md-5 col-form-label">CÁ MẬP
                                            </label>
                                            <div className="col-lg-8 col-md-7">
                                                <input type="text" className="form-control tp-input" id="whoShark" name="whoShark"
                                                    value={whoShark}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                        <div className=" mb-3">
                                            <label htmlFor="whoExpectation" className="form-label">KẾ HOẠCH VÀ KỲ VỌNG CỦA
                                                ĐÁM ĐÔNG</label>
                                            <textarea className="form-control tp-txtarea" id="whoExpectation" name="whoExpectation"
                                                value={whoExpectation}
                                                onChange={(event) => _this.onChange(event)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="header-bullet"></div>
                                    <div className="header-separator"></div>
                                    <span className="header-text text-uppercase">
                                        <span className="step-1">BƯỚC 6</span> - <span className="step-2">WHY</span>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <div className="row mb-3">
                                            <label htmlFor="whyRiskRewardRatio" className="col-8 col-form-label">1. TỶ LỆ LỢI
                                                NHUẬN
                                            </label>
                                            <div className="col-4">
                                                <input type="text" className="form-control tp-input" id="whyRiskRewardRatio" name="whyRiskRewardRatio"
                                                    value={whyRiskRewardRatio}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="whyWinrate" className="col-8 col-form-label">2. XÁC XUẤT THẮNG
                                            </label>
                                            <div className="col-4">
                                                <input type="text" className="form-control tp-input" id="whyWinrate" name="whyWinrate" readOnly
                                                    value={whyWinrate}
                                                    onChange={(event) => _this.onChange(event)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 d-block d-sm-none">
                                <div className="col d-flex justify-content-center">
                                    <div className="d-grid gap-2 col-md-6 col-12">
                                        <button className="btn btn-primary" onClick={(event) => _this.onExportPlan(event)}>TẢI KẾ HOẠCH VỀ MÁY</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default templateBak;